<div fxLayout="column" class="login-component">
  <div>
    <div class="login heading-lbl" fxHide.lt-sm fxShow.gt-xs>
      <p class="heading">Login to My Account</p>
      <span class="close-pop-up fa fa-lg fa-remove" (click)="onClick()"></span>
    </div>
    <div class="tab">
      <p class="login-tab active" (click)="changeTab($event,loginTab,signUpForm,login,signUp, errorLabel)" #login>Login</p>
      <p class="signup-tab" (click)="changeTab($event,loginTab,signUpForm,login,signUp,errorLabel)" #signUp>Signup</p>
    </div>
    <p class="error-lbl" #errorLabel ></p>
    <div class="login-form" #loginTab>
      <form fxHide.lt-sm fxShow.gt-xs role="form" [formGroup]="loginForm" (ngSubmit)="loginSubmit($event, errorLabel)" class="login-desk-view">
        <div fxHide.lt-sm fxShow.gt-xs class="login-email">
          <p class="text-label"> Email Address* </p>
          <input formControlName="email" type="email" tabindex="1" id="txtEmailLogin" class="txtFieldCls txtEmailLogin" style="margin:0;">
        </div>
        <!-- <input fxShow.lt-sm fxHide.gt-xs formControlName="email" type="email" tabindex="1" id="txtEmailLogin" class="txtFieldCls txtEmailLogin" -->
          <!-- style="margin:0;" placeholder="Your Email"> -->
        <div fxHide.lt-sm fxShow.gt-xs class="login-password">
          <p id="lblPasswordLogin" class="text-label text-pwd">Password</p>
          <input formControlName="password" type="password" tabindex="2" id="txtPasswordLogin" class="txtFieldCls txtPasswordLogin">
        </div>
        <!-- <input fxShow.lt-sm fxHide.gt-xs formControlName="password" type="password" tabindex="2" id="txtPasswordLogin" class="txtFieldCls txtPasswordLogin" -->
          <!-- placeholder="Password"> -->
        <div>
          <div class="chckbx">
            <input formControlName="remember" class="chckbx-selector" type="checkbox" tabindex="3" id="rememberMeChk">
            <label class="chckbx-label" for="rememberMeChk">Remember Me</label>
          </div>
          <a (click)="goToForgotPw()" tabindex="5" id="lnkForgotPwd" class="lnkForgotPwd">Forgot password?</a>
        </div>
        <div class="button-div">
            <button id="lnkLoginBtn" class="button lnkLoginBtn" tabindex="4" >Login</button>
        </div>
      </form>
      <form fxShow.lt-sm fxHide.gt-xs role="form" [formGroup]="loginMobileForm" (ngSubmit)="loginSubmit($event, errorLabel)" class="login-mobile-view">
        <input fxShow.lt-sm fxHide.gt-xs formControlName="email" type="email" tabindex="1" id="txtEmailLogin" class="txtFieldCls txtEmailLogin"
          style="margin:0;" placeholder="Your Email">
        <input fxShow.lt-sm fxHide.gt-xs formControlName="password" type="password" tabindex="2" id="txtPasswordLogin" class="txtFieldCls txtPasswordLogin"
          placeholder="Password">
        <div>
          <div class="chckbx">
            <input formControlName="remember" class="chckbx-selector" type="checkbox" tabindex="3" id="rememberMeChk">
            <label class="chckbx-label" for="rememberMeChk">Remember Me</label>
          </div>
          <a (click)="goToForgotPw()" tabindex="5" id="lnkForgotPwd" class="lnkForgotPwd">Forgot password?</a>
        </div>
        <button id="lnkLoginBtn" class="button lnkLoginBtn" tabindex="4" >Login</button>
      </form>
    </div>
    <div class="signup-form hide" #signUpForm>
      <form fxHide.lt-sm fxShow.gt-xs id="frmSignup" [formGroup]="registerForm" (ngSubmit)="signUpSubmit($event, errorLabel)" class="sign-up-view">

        <div id="signUpPnl" class="signUpPnl">
          <div class="signup-name" fxHide.lt-sm fxShow.gt-xs>
            <p id="lblNameSignup" class="lblFieldClass text-label"> Name * </p>
            <input type="text" formControlName="name" tabindex="1" id="txtNameSignup" class="txtFieldCls txtNameSignup">
          </div>
          <!-- <input fxShow.lt-sm fxHide.gt-xs type="text" formControlName = "name" tabindex = "1" id = "txtNameSignup" class = "txtFieldCls txtNameSignup" -->
            <!-- placeholder = "Name*"> -->

          <div fxHide.lt-sm fxShow.gt-xs class="signup-email">
            <p id="lblEmailSignup" class="lblFieldClass text-label">Email Address* </p>
            <input type="email" formControlName="emailSignUp" tabindex="1" id="txtEmailSignup" class="txtFieldCls txtEmailSignup">
          </div>
          <!-- <input fxShow.lt-sm fxHide.gt-xs type="text" formControlName="name" tabindex="1" id="txtNameSignup" class="txtFieldCls txtNameSignup" -->
            <!-- placeholder="Email Address*"> -->
          <div class="signup-password" fxHide.lt-sm fxShow.gt-xs>
            <p id="lblPasswordSignup" class="lblFieldClass text-label"> Password</p>
            <input type="password" tabindex="3" formControlName="passwordSignUp" id="txtPasswordSignup" class="txtFieldCls txtPasswordSignup">
            <div id="pnlStrength" class="pnlStrength">
              <span id="spPwdStrength"></span>
            </div>
          </div>
          <!-- <input fxShow.lt-sm fxHide.gt-xs type="password" tabindex="3" formControlName="passwordSignUp" id="txtPasswordSignup" class="txtFieldCls txtPasswordSignup" -->
            <!-- placeholder="Password"> -->
          <button id="lnkSignupBtn" class="button lnkSignupBtn" tabindex="4" > Signup</button>
        </div>
      </form>
      <form fxShow.lt-sm fxHide.gt-xs id="frmSignup" [formGroup]="mobileRegisterForm" (ngSubmit)="signUpSubmit($event, errorLabel)" class="sign-up-mob-view">

        <div id="signUpPnl" class="signUpPnl">
          <input fxShow.lt-sm fxHide.gt-xs type="text" formControlName="name" tabindex="1" id="txtNameSignup" class="txtFieldCls txtNameSignup"
            placeholder="Name*">
          <input fxShow.lt-sm fxHide.gt-xs type="text" formControlName="emailSignUp" tabindex="1" id="txtNameSignup" class="txtFieldCls txtNameSignup"
            placeholder="Email Address*">
          <input fxShow.lt-sm fxHide.gt-xs type="password" tabindex="3" formControlName="passwordSignUp" id="txtPasswordSignup" class="txtFieldCls txtPasswordSignup"
            placeholder="Password">
          <button id="lnkSignupBtn" class="button lnkSignupBtn" tabindex="4"> Signup</button>
        </div>
      </form>
    </div>
  </div>
  <div fxHide.lt-sm fxShow.gt-xs class="loginVia" [ngClass]="{'hide': !fbOauth && !googleOauth}">
    <div *ngIf="fbOauth" id="fbPnl" class="fbPnl fbPnlPresent" tabindex="10" (click)="socialSignIn('facebook', errorLabel)">
      <a id="fbLnk" class="lnkFB">Sign In with Facebook </a>
    </div>
    <div id="gPlusPnl" *ngIf="googleOauth" class="gPlusPnl" tabindex="11" (click)="socialSignIn('google', errorLabel)">
      <a id="gPlusLnk" class="gPlusLnk">Sign In with Google</a>
    </div>
  </div>

  <div fxShow.lt-sm fxHide.gt-xs class="login-via-mobile" [ngClass]="{'hide': !fbOauth && !googleOauth}">
    <p>OR</p>
    <div *ngIf="fbOauth" id="fbPnl" class="fbPnl fbPnlPresent" tabindex="10" (click)="socialSignIn('facebook', errorLabel)">
        <a id="fbLnk" class="lnkFB">Sign In with Facebook </a>
      </div>
      <div *ngIf="googleOauth" id="gPlusPnl" class="gPlusPnl" tabindex="11" (click)="socialSignIn('google', errorLabel)">
          <a id="gPlusLnk" class="gPlusLnk">Sign In with Google</a>
        </div>
    <!-- <a *ngIf="fbOauth" id="fb-mob-lnk" class="fb-mob" (click)="socialSignIn('facebook')"></a> -->
    <!-- <a *ngIf="googleOauth" id="gplus-mob-lnk" class="gplus-mob" (click)="socialSignIn('google')"></a> -->
  </div>

</div>