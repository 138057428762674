<div class="pagesContentContainer">
  <div fxFlex="100" fxLayout="column" class="panel">
    <div fxLayout="column" class="pageContentPanel">
      <div fxLayoutAlign="center center" class="errorPage" *ngIf="showError">
        <img [src]="errorPageUrl" style="max-height:100%; max-width:100%;">
      </div>
      <div fxLayout="column"  *ngIf="!showError">
        <div fxLayout="row" fxLayout.lt-sm="column" class="productUpperPanel">
          <div fxShow.lt-sm fxHide.gt-xs class="mb-proTitle">
            <h2 class="mb-heading">{{productName}}</h2>
          </div>
          <div fxLayout="column" class="productImageMainPanel">
            <div class="productImagePanel" [ngClass]="{'opacity-none': playVideo}">
              <div class="addWishPanel fa fa-heart-o hide" (click)="addToCartWishList()" *ngIf="allowWishlist"></div>
              <div fxShow.gt-xs fxHide.lt-sm fxLayoutAlign="center center" class="mediumImage" (mouseout)="hideZoomImage()"
                (mousemove)="zoomImgBgPos($event)" (click)="showZoomImgDialog(imageThumbnailsList,productDetails?.productName, image)">
                <img [src]="image != undefined?image:defaultPath" title="{{productDetails?.productName}}" alt="" (error)="imgErrorHandler($event)" >
              </div>
              <div fxHide.gt-xs fxShow.lt-sm fxLayoutAlign="center center" class="mediumImage" (click)="showZoomImgDialog(imageThumbnailsList,productDetails?.productName, image)">
                <img [src]="image != undefined?image:defaultPath" title="{{productDetails?.productName}}" alt="" (error)="imgErrorHandler($event)" >
              </div>
            </div>
            <div fxLayout="row" class="imageThumbnailPanel">
              <div fxFlex="5" fxLayoutAlign.gt-xs="end center" fxLayoutAlign.lt-sm="center center" class="leftArrowPnl">
                <span class="arrow fa fa-chevron-left" [ngClass]="{'opacity-none': imageThumbnailsList.length <= 3}"
                  (click)="slideRight()"></span>
              </div>
              <div fxFlex="90" class="imgThumbnailContainer">
                <div class="thumbnailIterator" [style.left]="leftUpdatedValue + '%'">
                  <div *ngFor="let thumbnail of imageThumbnailsList; let i=index" class="thumbnailDiv" (mouseover)="updateImageSrc(i)"
                    (click)="updateImageSrc(i)">
                    <img [src]="thumbnail != undefined?thumbnail:defaultPath" class="center-position" title="{{productDetails?.productName}}" alt="" (error)="imgErrorHandler($event)">
                  </div>
                </div>
              </div>
              <div fxFlex="5" fxLayoutAlign.gt-xs="end center" fxLayoutAlign.lt-sm="center center" class="rightArrowPnl">
                <span class="arrow fa fa-chevron-right" [ngClass]="{'opacity-none': imageThumbnailsList.length <= 3}"
                  (click)="slideLeft()"></span>
              </div>
            </div>
            <div class="zoomImgDivOuter" [ngClass]="{'hide': productImageHover == false || !image}">
              <div class="zoomImgDiv" #productMainImage [style.background-image]="'url('+image+')'"></div>
            </div>
            <div fxHide.lt-sm style="position: relative" *ngIf="youtubeEmbedLink">
              <div class="playVideoPnl">
                <span class="playVideoIcon fa fa-play-circle-o"></span>
                <a [ngClass]="{'hide':playVideo}" (click)="playProVideo()">Play Video</a>
                <a [ngClass]="{'hide':!playVideo}" (click)="showProImages()">Back to Images</a>
              </div>
            </div>
            <div class="videoOuterPnl" *ngIf="playVideo">
              <iframe class="video" allowfullscreen="" webkitallowfullscreen="" mozallowfullscreen="" [src]="youtubeEmbedLinkUrl | safeUrl"></iframe>
            </div>
          </div>
          <div fxlayout="column" class="productDetailPanel">
            <div fxHide.lt-sm class="productTitlePanel">
              <h2 class="productTitleHeading">{{productName}}</h2>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" class="productRateRevPanel">
              <div class="rating" *ngIf="giveRating > 0">
                <ls-rating [editable]=false name="giveRating" [(ngModel)]="giveRating"></ls-rating>
              </div>
              <p class="totalReviewLbl" *ngIf="totalReviews.length > 0">{{totalReviews.length}} Review</p>
              <a class="writeReviewLink" (click)="addReview()">
                <span class="writeReviewSpan fa fa-pencil"></span>
                {{addReviewLbl}}
              </a>
              <div fxHide.gt-xs fxShow.lt-sm class="sharePnl" (click)="openSharingDialog()">
                <div class="shareIcon"></div>
              </div>
            </div>
            <div fxLayout="column" class="productDelPnl">
              <div fxLayout="column" class="pricePanel">
                <div fxLayout="row">
                  <div fxFlex.lt-sm="50" fxLayout="row">
                    <div fxHide.lt-sm class="pnlPriceTag">
                      <p>{{priceTag}}</p>
                    </div>
                    <div fxLayout="column">
                      <div fxLayout="row" style="margin-top: 10px;">
                        <div fxLayout="row" fxLayoutAlign="start center" class="oldPricePnl" *ngIf="mrp > salePrice && productDetails?.showSaving == 1 && discountPercent > 0">
                          <p class="oldPriceLbl">{{mrp | currency:currType:'symbol': priceRoundOffInteger}}</p>
                        </div>
                        <div fxLayoutAlign="start center" class="discountPnl hide">
                          <p class="lblSavingMrp">{{discountPercent | currency:currType:'symbol':
                            discountRoundOffInteger}}%</p>
                        </div>
                        <div fxLayoutAlign="start center" class="currPricePnl">
                          <p class="currPriceLbl">{{salePrice | currency:currType:'symbol': priceRoundOffInteger}}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div fxFlex.lt-sm="50" fxShow.lt-sm fxHide.gt-xs fxLayout="column" class="shippingPanel">
                    <p class="shippingDaysLabel">Estimated Shipping in :
                      {{productDetails?.shippingDays}}-{{shippingDays}}days</p>
                    <a *ngIf="showReturnFlag == true" class="returnPolicyLink" (click)="goToReturnAndRefund()" target="_blank">Easy
                      Return Policy</a>
                  </div>
                </div>
                <div fxShow.lt-sm fxHide.gt-xs fxLayoutAlign="start center" fxLayout="row">
                  <div fxLayoutAlign="center center" class="freeShippingPnl" *ngIf="productDetails?.shippingCharges == 0">
                    <p class="freeShippingLbl">{{freeShippingLbl}}</p>
                  </div>
                </div>
              </div>
              <div fxLayout="row" class="pnlForQuantity">
                <div fxHide.lt-sm class="pnlQuantityTag">
                  <p class="lblQuantityTag">{{quantityTag}}</p>
                </div>
                <div fxLayoutAlign="start center" class="pnlSpinner">
                  <div fxLayout="row" class="spinner">
                    <div class="firstDiv">
                      <input value="{{productQuantity}}" disabled />
                    </div>
                    <div fxLayout="column" class="secondDiv">
                      <div fxLayoutAlign="center center" class="prodQuantitySpinnerUp fa fa-chevron-up" (click)="increaseProQuantity()"></div>
                      <div fxLayoutAlign="center center" class="prodQuantitySpinnerDown  fa fa-chevron-down" (click)="decreaseProQuantity()"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div fxLayout="row" class="pnlForStatus">
                <div fxHide.lt-sm class="pnlQuantityTag">
                  <p class="lblQuantityTag">{{statusTag}}</p>
                </div>
                <div fxLayoutAlign="start center" class="lblStatus" *ngIf="isoutOfStock == true">
                  <p>{{outOfStockTag}}</p>
                </div>
                <div fxLayoutAlign="start center" class="lblStatus" *ngIf="isProNotAvailable == false && isoutOfStock == false">
                  <p>{{availableTag}}</p>
                </div>
                <div fxLayoutAlign="start center" class="lblStatus" *ngIf="isProNotAvailable == true && isoutOfStock == false">
                  <p>{{notAvailableTag}}</p>
                </div>
              </div>
              <div fxHide.lt-sm class="shipmentPanel" *ngIf="(productDetails?.shippingCharges > 0 || productDetails?.cod > 0 || (showGst && gst > 0) || productDetails?.handlingCharges > 0)">
                <div fxLayout="row" class="shippingChargesForProductDetail" *ngIf="productDetails?.shippingCharges > 0">
                  <div class="tagShippingCharges">
                    <p>{{shippingChargesTag}}</p>
                  </div>
                  <div fxLayout="row" fxLayoutAlign="start center" class="lblShippingCharges">
                    <p>{{productDetails?.shippingCharges | currency:currType:'symbol': priceRoundOffInteger}}</p>
                  </div>
                </div>
                <div fxLayout="row" class="codChargesForProductDetail" *ngIf="productDetails?.cod > 0">
                  <div class="tagCodCharges">
                    <p>{{codChargesTag}}</p>
                  </div>
                  <div fxLayout="row" fxLayoutAlign="start center" class="lblCodCharges">
                    <p>{{productDetails?.cod | currency:currType:'symbol': priceRoundOffInteger}}</p>
                  </div>
                </div>
                <div fxLayout="row" class="gstChargesForProductDetail" *ngIf="showGst && gst > 0">
                  <div class="tagGstCharges">
                    <p>{{gstChargesTag}}</p>
                  </div>
                  <div fxLayout="row" fxLayoutAlign="start center" class="lblGstCharges">
                    <p>{{gst | currency:currType:'symbol': priceRoundOffInteger}}</p>
                  </div>
                </div>
              </div>
              <div class="vatPanelCls"></div>
              <div fxHide.lt-sm fxLayout="row" class="pnlHandlingCls" *ngIf="productDetails?.handlingCharges > 0">
                <div class="tagHandlingCharges">
                  <p>{{handlingChargesTag}}</p>
                </div>
                <div fxLayout="row" fxLayoutAlign="start center" class="lblHandlingCharges">
                  <p>{{productDetails?.handlingCharges | currency:currType:'symbol': priceRoundOffInteger}}</p>
                </div>
              </div>
              <div class="pnlFilterItrOuter">
                <ul class="filterItr">
                  <li class="filterItrLi" *ngFor="let variantType of variantTypes; let variantTypeIdx = index;">
                    <div class="pnlMandtryFieldInner">
                      <p class="variantDesc">{{variantType.variantHeading}}</p>
                      <ul class="variantItr">
                        <li class="variantItrLi" [ngClass]="{'filterSelected' : selectionArray && (optionIdx == selectionArray[variantTypeIdx]) }"
                          *ngFor="let variant of variantType.options; let optionIdx = index;" (click)="setProductVariant($event, variantTypeIdx, optionIdx, variant.mappedUniqueIds)">
                          <p class="filter">{{variant.option}}</p>
                        </li>
                      </ul>
                    </div>
                  </li>
                </ul>
              </div>
              <div class="keyFeaturesPnl">
                <div class="buttons">
                  <div class="buyNowPnl" *ngIf="isoutOfStock == false || (productDetails?.acceptOrderOnOutOfStock == 1 && isoutOfStock == true)">
                    <button class="buyNowBtn" (click)="buyProduct()">{{buyNowLbl}}</button>
                  </div>
                  <div class="addToCartPnl" *ngIf="(isoutOfStock == false && allowCart) || (isoutOfStock == true && allowCart && productDetails?.acceptOrderOnOutOfStock == 1)">
                    <button class="buyNowBtn" (click)="addToCartProduct()">{{addToCartLbl}}</button>
                  </div>
                  <div class="addToCartPnl" *ngIf="allowWishlist">
                    <button class="addToCartBtn" (click)="addToCartWishList()">Add to Wishlist</button>
                  </div>
                </div>
              </div>
              <div class="pnlForEnquiry" *ngIf="enquiryMode == true || buyAndEnquiryMode == true">
                <div fxLayoutAlign="center center" class="callForPriceBtn">
                  <a [href]="'enquiry?t='+productDetails?.titleId+'&s=0'">{{callForPriceTag}}</a>
                </div>
              </div>
              <div fxHide.lt-sm fxLayout="column" class="shippingPanel">
                <p class="shippingDaysLabel">Estimated Shipping in : {{shippingDays}}-{{shippingDays+2}}days</p>
                <a *ngIf="showReturnFlag == true" class="returnPolicyLink" (click)="goToReturnAndRefund()" target="_blank">Easy
                  Return Policy</a>
                <div fxLayoutAlign="start center" fxLayout="row" style="margin-top: 20px;">
                  <div fxLayoutAlign="center center" class="freeShippingPnl" *ngIf="productDetails?.shippingCharges == 0">
                    <p class="freeShippingLbl">{{freeShippingLbl}}</p>
                  </div>
                </div>
              </div>
              <div class="minOrderQtyPnl"></div>
              <div class="productFeaturesPanel" [ngClass]="{'hide':productDetails?.desc == undefined && productDetails?.desc == null}">
                <div class="proFeatureMainHeading">
                  <h3>{{aboutProduct}}</h3>
                </div>
                <div class="proFeatureInnerpanel">
                  <div class="featureSnippet" [style.height]="_readAll ? 'auto': '47px'">
                    <p #featureSnippet [innerHtml]="productDetails?.desc"></p>
                  </div>
                  <a class="viewAllLink" (click)="readAll()" *ngIf="_showReadAll" [ngClass]="{'hide':_readAll}">Read
                    More</a>
                </div>
              </div>
              <div fxHide.lt-sm class="socialSharePanel">
                <div fxLayout="column" fxLayoutAlign="center center" class="socialLinks">
                  <a *ngIf="isFbHash" class="socialLink fb" href={{socialShareFbUrl}} target="_blank"></a>
                  <a *ngIf="isGoogleHash" class="socialLink google" href={{socialShareGoogleUrl}} target="_blank"></a>
                  <a *ngIf="isLinkedInHash" class="socialLink linkedIn" href={{socialShareLinkedInUrl}} target="_blank"></a>
                  <a *ngIf="isTwitterHash" class="socialLink twitter" href={{socialShareTwitterUrl}} target="_blank"></a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div fxLayout="column" class="productSpecPanel" *ngIf="featureArray && featureArray.length > 0">
          <div class="heading">
            <!-- <div fxLayoutAlign.gt-xs="center center" class="productSpecTypeHeading"> -->
            <h3>Features</h3>
            <!-- </div> -->
          </div>
          <div class="specPanel" *ngFor="let feature of featureArray">
            <h3>{{feature.name}}</h3>
            <div class="attributeContainer">
              <ng-container *ngFor="let detail of feature.details">
                <div fxLayout="row" class="attribute">
                  <div class="attrName">{{detail.key}}</div>
                  <div class="attrValue">{{detail.value}}</div>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
        <div fxLayout="column" class="productReviewPanel" *ngIf="ratingAndReviewData">
          <div class="heading">
            <h3>{{lblCustomerReviews}}</h3>
          </div>
          <div fxLayout="column" class="pnlRateReview">
            <div fxLayout="row" fxLayout.lt-sm="column" class="pnlRating">
              <div fxLayout.gt-xs="column" fxLayout.lt-sm="row" class="currRatingPanel">
                <p fxFlex.gt-xs="20" fxFlex.lt-sm="50" class="rateLbl">{{rateLbl}}</p>
                <p fxFlex.gt-xs="20" fxFlex.lt-sm="50" class="currRateLbl">{{ratingAndReviewData.productRatingAvg |
                  number:'1.1-1'}}</p>
              </div>
              <div fxLayout="column" class="ratingSummaryPanel">
                <p class="ratingSummaryLbl">{{ratingSummaryLbl}}</p>
                <div fxLayout="row" class="barGraphPnl">
                  <div fxLayout="row" class="barRatingPnl" *ngFor="let ratingPnl of ratingPnls; let i = index;">
                    <span #barRatingPnlsValue class="barValue"></span>
                    <div #barRatingPnlsBar class="barDiv"></div>
                    <span class="ratingVal">{{i+1}}</span>
                  </div>
                </div>
              </div>
              <div class="rateNowPanel">
                <p class="rateNowLabel">{{rateNowLabel}}</p>
                <div class="writeReviewPanel">
                  <button (click)="addReview()">{{rateNowBtnLabel}}</button>
                </div>
              </div>
            </div>
            <div class="pnlReview">
              <div *ngFor="let reviewModel of ratingAndReviewData.reviewModels">
                <div fxLayout="column" class="customerReviewPnl">
                  <ls-rating [editable]=false name="reviewPnlGetRating" [(ngModel)]="reviewModel.productRating"></ls-rating>
                  <p class="userNameLabel">{{reviewModel.username}}</p>
                  <p class="postingDateLabel">{{reviewModel.createDate | date:'EEE MMM dd HH:mm:ss zzz yyyy'}}</p>
                  <p class="reviewTitleLabel">{{reviewModel.reviewTitle}}</p>
                  <p class="userReviewLabel">{{reviewModel.reviewText}}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="otherRecentRelatedPanel"></div>
        <div class="detailsPageGridPnl">
          <app-home-products [newProd]="newProd" ></app-home-products>
        </div>
      </div>
    </div>
  </div>
</div>