<div id="cartWrapper">
    <div id="cartHeadDiv" class="cartHeadDiv">
        <p id="cartShotCrtLbl" class="lblShop">Your Bag</p>
        <span id="cartCloseBtn" class="fa fa-remove cartCloseBtn" (click)="onClose()">       
        </span>
    </div>
    <div *ngIf="emptyCart" id="cartEmptyDiv" class="cartEmptyDiv">
        <div id="cartEmptyDivInner" class="cartEmptyDivInner">
            <p id="lblEmptyBag" class="lblEmptyBag">Your Bag is Empty!!</p>
            <a (click)="onClose()" id="lnkGoShopping" class="lnkGoShopping">Continue Shopping...</a>
        </div>
    </div>
    <div *ngIf="!emptyCart" id="cartTblTitlDiv" class="cartTblTitlDiv">
        <p id="cartTblTitlLbl_1" class="col-xs-7 cartTblTitlLbl">Product</p>
        <p id="cartTblTitlLbl_2" class="col-xs-3 cartTblTitlLbl">Quantity</p>
        <p id="cartTblTitlLbl_3" class="col-xs-2 cartTblTitlLbl">Price</p>
    </div>
    <div *ngIf="!emptyCart" class="cartItemItrDiv">
        <div *ngFor="let cartProduct of cartProducts; let cartProdIdx = index;" class="cartItmDetlsDiv">
            <div class="cartItmContainer" *ngIf="!cartProduct?.offer">
                <div class="productImageContainer">
                    <div class="imageDiv">
                        <a [href]="'details/'+cartProduct.productModel.productURLText+'?t='+cartProduct.productModel.titleId" class="imageLink">
                            <img class = "productImage img-responsive center-position" [src]="(cartProduct?.productModel?.imageUrl && cartProduct?.productModel?.images && cartProduct?.productModel?.images[0])? 
                            cartProduct?.productModel?.imageUrl +'m/'+ cartProduct?.productModel?.images[0]:defaultPath" alt="" (error)="imgErrorHandler($event)" >                            
                        </a>
                    </div>
                </div>
                <div class='productDetails'>
                    <div class="detailsDiv">
                        <a id="cartItemTtl" class="cartItemTtl " title="cartProduct.productModel.productName">{{cartProduct.productModel.productName}}</a>
                        <div class="variantWrapper">
                            <p *ngIf="cartProduct.productModel.variantAttributes != undefined" class="variant">{{setVariantName(cartProduct.productModel.variantAttributes)}}</p>
                        </div>
                        <a id="lnkWishList" class="lnkWishList col-xs-1 noPadding hide" title="Add to Wishlist">
                            <span id="imgWishList" class="fa fa-heart-o">
                            </span>
                        </a>
                        <div class="cartDelete" title="Delete">
                            <span (click)='removeProduct(cartProduct, cartProdIdx)' class="cartDeleteIcon fa fa-trash">                        
                            </span>
                        </div>
                    </div>
                    <div class="productQuantity">
                        <div fxLayoutAlign="center center" class="pnlSpinner">
                            <div fxLayout="row" class="spinner">
                                <div class="firstDiv">
                                    <input value="{{cartProduct.numCopies}}" disabled />
                                </div>
                                <div fxLayout="column" class="secondDiv">
                                    <div fxLayoutAlign="center center" class="prodQuantitySpinnerUp fa fa-chevron-up" (click)="increaseProQuantity(cartProduct, cartProdIdx)"></div>
                                    <div fxLayoutAlign="center center" class="prodQuantitySpinnerDown  fa fa-chevron-down" (click)="decreaseProQuantity(cartProduct, cartProdIdx)"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class='productPrice'>
                        <div class="priceDiv">
                            <p id="productMrp" *ngIf="cartProduct.productModel.mrp > cartProduct.productModel.salePrice">
                                {{cartProduct.productModel.mrp | currency:currType:'symbol': priceRoundOffInteger}}
                            </p>
                            <p id="productSalePrice">{{cartProduct.productModel.salePrice | currency:currType:'symbol': priceRoundOffInteger}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="!emptyCart" class="cartGrandTotalDiv">
        <div class="couponAmount" *ngIf="couponApplied">
            <p class="couponDesc">{{couponApplied}} Coupon Applied</p>
            <span id="text">Coupon Amount:</span>
            <span id="value">{{couponAmount | currency:currType}}</span>
        </div>
        <div *ngIf="shippingCharges > 0" class="shippingPanel">
            <span id="text">Shipping Charges:</span>
            <span id="value">{{shippingCharges| currency:currType:'symbol': priceRoundOffInteger}}</span>
        </div>
        <div *ngIf="handlingCharges > 0" class="handlingPanel">
            <span id="text">Handling Charges:</span>
            <span id="value">{{handlingCharges| currency:currType:'symbol': priceRoundOffInteger}}</span>
        </div>
        <div class="taxesPanel">
            <span id="text">GST Charges:</span>
            <span id="value">{{taxes| currency:currType:'symbol': priceRoundOffInteger}}</span>
        </div>
        <div class="totalSavings">
            <span id="text">Total Savings:</span>
            <span id="value">{{totalSavings | currency:currType:'symbol': priceRoundOffInteger}}</span>
        </div>
        <div class="grandTotal">
            <span id="text"> Grand Total: ({{this.cartNum}}&nbsp;Item)</span>
            <span id="value">{{grandTotal | currency:currType:'symbol': priceRoundOffInteger}}</span>
        </div>
    </div>
    <div *ngIf="!emptyCart" class="cartFooterDiv">
        <a class="shopMore" (click)="onClose()">Shop More</a>
        <!-- <a [class.gayab]="!enquireVisibility" (click)="enquiryClick()" [routerLink]="'enquiry'" class="enquireNow">Enquire Now</a> -->
        <a (click)="proceedToCheckout()" class="checkout">Proceed to Checkout</a>
    </div>
</div>