import { Component, OnInit, Inject, PLATFORM_ID, ViewChild, OnDestroy, Input } from '@angular/core';
import { Router, RouterEvent, NavigationEnd } from '@angular/router';
import { HomePageService } from '../../../../../services/home-page.service';
import { CollectionModel } from '../../../../../common/models/collectionModel';
import { isPlatformBrowser } from '@angular/common';
import { Constants } from '../../../../../common/constants/lsnetx.constants';

@Component({
  selector: 'app-home-products',
  templateUrl: './home-products.component.html',
  styleUrls: ['./home-products.component.scss']
})
export class HomeProductsComponent implements OnInit, OnDestroy {

  left: number = 0
  rightVisibility: boolean = true
  leftVisibility: boolean = false
  slideConst: number = 0;
  slideLength: number
  iteration: number = 0
  currIteration: number = 1
  collectionArray: Array<CollectionModel> = [];
  leftValue: Array<number> = [];
  maxLeftValue: Array<number> = [];
  showCount: number = 4;
  @ViewChild('container',{static: false}) container: any;
  isDetailPage: boolean = false;
  _routeSubscription;
  CONSTANTS = Constants;
  @Input('newProd') newProd;

  constructor(
    private homePageService: HomePageService,
    @Inject(PLATFORM_ID) private platformId: Object,
    private router: Router
  ) { }

  ngOnInit() {
    if (this.router.url.includes('details/')) {
      this.isDetailPage = true;
    } else {
      this.isDetailPage = false;
    }
    this._routeSubscription = this.router.events.subscribe((e: RouterEvent) => {
      if (e instanceof NavigationEnd) {
        let newUrl = e.url;
        if (newUrl.includes('details/')) {
          this.isDetailPage = true;
        } else {
          this.isDetailPage = false;
        }
      }
    });
    if (!this.isDetailPage) {
      this.getCollections();
    }
    if (isPlatformBrowser(this.platformId)) {
      if (window.innerWidth < 768) {
        this.showCount = 2;
      }
    }
  }

  getCollections() {
    var fetchProducts = true, categoryId;
    this.collectionArray = [];
    if (this.router.url.includes('details/')) {
      this.isDetailPage = true;
    } else {
      this.isDetailPage = false;
    }
    this._routeSubscription = this.router.events.subscribe((e: RouterEvent) => {
      if (e instanceof NavigationEnd) {
        let newUrl = e.url;
        if (newUrl.includes('details/')) {
          this.isDetailPage = true;
        } else {
          this.isDetailPage = false;
        }
      }
    });
    if (this.isDetailPage) {
      if (this.newProd && this.newProd.titleId && this.newProd.categoryId) {
        categoryId = this.newProd.categoryId
      }
    }
    this.homePageService.getHomePageCollectionIn(this.isDetailPage, fetchProducts, categoryId, (data) => {
      if (data != null && !data.error) {
        if (data.data != null || data.data != undefined) {
          let collection: Array<CollectionModel> = data.data;
          if (collection && collection.length > 0) {
            collection.forEach(element => {
              this.collectionArray.push(element);
            });
          }
          this.homePageService.collectionsBehaviour.next(this.collectionArray);
          for (let index = 0; index < this.collectionArray.length; index++) {
            this.leftValue.push(0);
          }
        }
      }
    });
  }

  ngOnChanges() {
    if (this.newProd) {
      /**
       * set products scroll to initial
       */
      this.leftValue = [];
      this.getCollections();
    }
  }

  ngOnDestroy() {
    if (this._routeSubscription) {
      this._routeSubscription.unsubscribe();
    }
  }

  initializeSliderSettings(container, idx) {
    if (this.slideConst == 0)
      this.slideConst = container.nativeElement.offsetWidth;
    this.maxLeftValue[idx] = -this.slideConst * (this.collectionArray[idx].productModels.length - this.showCount);
  }

  moveLeft(container, idx) {
    this.initializeSliderSettings(container, idx);
    if (this.leftValue[idx] > this.maxLeftValue[idx]) {
      this.leftValue[idx] -= this.slideConst * this.showCount + 10 * this.showCount;
    }
  }

  moveRight(container, idx) {
    this.initializeSliderSettings(container, idx);
    if (this.leftValue[idx] < 0) {
      this.leftValue[idx] += this.slideConst * this.showCount + 10 * this.showCount;
    }
  }

}
