<div class="catHeadingWrapper">
  <p class="catHeading">Our Offer</p>
</div>
<div class="offerWrapper">
  <div class="offer" *ngFor="let offer of offers; let idx  = index" [title]="offer?.altText">
    <img (click)="navigate(offer.imageHref)" class="offerImage" *ngIf="offer?.videoURL=='' || (!offer?.videoURL) "
      [src]="isMobile?offer.mobilUrl:offer.imageName ? offer.imageName:defaultPath" alt="" (error)="offerImgErrorHandler($event)">
    <p *ngIf="offer?.altText" class="offerText">{{offer?.altText}}</p>
    <iframe class="offerVideo" *ngIf="!(offer?.videoURL=='' || offer?.videoURL == undefined)" [src]="offer?.videoURL | safeUrl"
      frameborder="0"></iframe>
  </div>
</div>